<template>
  <v-tooltip v-bind="$attrs" :disabled="(disabled || display.smAndDown) && !forceTooltip" :text="title || text" :location="location">
    <template v-for="(_, slotName) in $slots" #[slotName]="props">
      <slot :name="slotName" v-bind="props" />
    </template>
  </v-tooltip>
</template>
<script setup>
import { useDisplay } from 'vuetify'
const display = ref(useDisplay())
defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  location: {
    type: String,
    default: 'bottom'
  },
  forceTooltip: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  text: {
    type: String,
    default: ''
  }
})
</script>
<style lang="scss" scoped>
:deep(.v-overlay__content) {
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 16px;
}
</style>
